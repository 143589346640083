<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-5">Evolução do Preço do Frete por Período</h5>
            <div class="row">
              <div class="col-md-12">
                <div class="chart-container chart-container-frete-periodo-linha">
                  <canvas id="FretePeriodoLinha"></canvas>
                  <div class="no-data FretePeriodoLinha" style="display: none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-1">Taxa cambial média</h5>
            <div class="mb-0 text-muted font-13">US$ - EUA</div>
            <div class="row">
              <div class="col-md-12">
                <div class="chart-container chart-container-frete-periodo-barra">
                  <canvas id="FretePeriodoBarra"></canvas>
                  <div class="no-data FretePeriodoBarra" style="display: none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import dayjs from 'dayjs'
import KpiService from '@/services/KPI/KpiService'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chart.js/dist/Chart.min.css'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// eslint-disable-next-line
import '@/assets/js/custom/data.chartJS.data.render.js'

// Por padrão label vem habilitada para todos os gráficos (Removendo para poder colocar apenas nos que desejar)
Chart.plugins.unregister(ChartDataLabels)

export default {
  name: 'FinantialVolume',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.volume.title') + ' - %s'
    }
  },
  data () {
    return {
      containerTypeList: [
        {
          'codigo': '000001',
          'label': "20' Tank",
          'code': 1
        },
        {
          'codigo': '000002',
          'label': "20' Plataform",
          'code': 2
        },
        {
          'codigo': '000003',
          'label': "20'Dry",
          'code': 3
        },
        {
          'codigo': '000004',
          'label': "20'OT In Gauge",
          'code': 4
        },
        {
          'codigo': '000005',
          'label': "20'FR Out Gauge",
          'code': 5
        },
        {
          'codigo': '000006',
          'label': "20'Reefer",
          'code': 6
        },
        {
          'codigo': '000007',
          'label': "40' Plataform",
          'code': 7
        },
        {
          'codigo': '000008',
          'label': "40'FR In Gauge",
          'code': 8
        },
        {
          'codigo': '000009',
          'label': "40'HC",
          'code': 9
        },
        {
          'codigo': '000010',
          'label': "40'Dry",
          'code': 10
        },
        {
          'codigo': '000011',
          'label': "40'OT In Gauge",
          'code': 11
        },
        {
          'codigo': '000012',
          'label': "40'RH",
          'code': 12
        },
        {
          'codigo': '000002',
          'label': '22G0',
          'code': 14
        },
        {
          'codigo': '000007',
          'label': "40'NOR",
          'code': 19
        },
        {
          'codigo': '000011',
          'label': "40'FR Out Gauge",
          'code': 23
        },
        {
          'codigo': '000014',
          'label': "20'FR In Gauge",
          'code': 25
        },
        {
          'codigo': '000015',
          'label': "40'RE",
          'code': 26
        },
        {
          'codigo': '000016',
          'label': "40'OT HC In Gauge",
          'code': 27
        },
        {
          'codigo': '000017',
          'label': "20' MAFI",
          'code': 28
        },
        {
          'codigo': '000018',
          'label': "40' MAFI",
          'code': 29
        },
        {
          'codigo': '000020',
          'label': "40'OT Out Gauge",
          'code': 30
        },
        {
          'codigo': '000021',
          'label': "20'OT Out Gauge",
          'code': 31
        },
        {
          'codigo': '000022',
          'label': "40'FR HC Out Gauge",
          'code': 32
        },
        {
          'codigo': '000023',
          'label': "40'FR HC In Gauge",
          'code': 33
        },
        {
          'codigo': '000028',
          'label': "40'HC Hard TOP",
          'code': 34
        },
        {
          'codigo': '000029',
          'label': "40'OT HC Out Gauge",
          'code': 35
        }
      ],
      // Charts
      fretePeriodoLinhaChart: null,
      fretePeriodoLinhaChartData: null,
      fretePeriodoBarraChart: null,
      fretePeriodoBarraChartData: null,
      kpiDados: null,
      isLoading: false,
      fullPage: true,
      activeFilterList: {
        period: null,
        beginDate: null,
        endDate: null,
        modal: null,
        operation: null,
        // processes: null,
        // type_cargo: null, // 1 : TEUS , 2: TONS
        tipo_equipamento: null,
        origin: null,
        destination: null
      },
      filter: {}
    }
  },
  components: {
    Loading
  },
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.buildCharts()

    if (this.user) {
      this.getKpiData()
    }
    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    setSelected (value) {
      this.filters.filTipoEquipamento = value
    },
    buildFilters () {
      this.filter.period = this.filters.filPeriodo

      // Operation Filter
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }
      if (this.filters.filTipoOperacaoModalNacional) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalNacional)
      }

      // Modal Filter
      this.filter.modal = []
      if (this.filters.filTipoOperacaoModalTerrestre) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalTerrestre)
      }
      if (this.filters.filTipoOperacaoModalMaritimo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalMaritimo)
      }
      if (this.filters.filTipoOperacaoModalAereo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalAereo)
      }

      // Filter Processes
      this.filter.processes = []
      if (this.filters.situacaoAbertos) {
        this.filter.processes.push(this.filters.situacaoAbertos)
      }
      if (this.filters.situacaoEmbarcados) {
        this.filter.processes.push(this.filters.situacaoEmbarcados)
      }

      // Period Date Filter
      this.filter.beginDate = this.filters.filPeriodoDtInicio
      this.filter.endDate = this.filters.filPeriodoDtFim

      // Origins and destination
      this.filter.origin = this.filters.filOrigem
      this.filter.destination = this.filters.filDestino
      this.filter.tipo_equipamento = this.filters.filTipoEquipamento
    },
    // Filter
    applyFilter () {
      let dayParse = 'DD/MM/YYYY'
      // Period

      if (this.$refs.view_type_weekly.classList.contains('active')) {
        this.filters.filPeriodo = 'weekly'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null
      this.filters.filTipoOperacaoModalNacional = this.$refs.operation_nac.checked ? parseInt(this.$refs.operation_nac.value) : null

      // Modal Filter
      this.filters.filTipoOperacaoModalTerrestre = this.$refs.modal_land.checked ? parseInt(this.$refs.modal_land.value) : null
      this.filters.filTipoOperacaoModalMaritimo = this.$refs.modal_mar.checked ? parseInt(this.$refs.modal_mar.value) : null
      this.filters.filTipoOperacaoModalAereo = this.$refs.modal_air.checked ? parseInt(this.$refs.modal_air.value) : null

      // Origin and Destination
      this.filters.filOrigem = this.$refs.origin.value ? this.$refs.origin.value : null
      this.filters.filDestino = this.$refs.destination.value ? this.$refs.destination.value : null

      // Tipo de Equipamento
      this.filters.filTipoEquipamento = this.$refs.tipo_equipamento.selectedValue ? this.$refs.tipo_equipamento.selectedValue : null

      this.filter.beginDate = dayjs(this.$refs.begin_date.value, dayParse).isValid() ? dayjs(this.$refs.begin_date.value, dayParse).format('YYYY-MM-DD') : ''
      this.filter.endDate = dayjs(this.$refs.end_date.value, dayParse).isValid() ? dayjs(this.$refs.end_date.value, dayParse).format('YYYY-MM-DD') : ''

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      /* Todo: Reload Charts */
      this.getKpiData()
    },
    resetFilter () {
      this.hideQuichSidebar()
      // hasFilter: false
      this.filters.filProcesso = null
      this.filters.filReferenciaCliente = null
      this.filters.filHBL = null
      this.filters.filPeriodoEmbarqueDtInicio = null
      this.filters.filPeriodoEmbarqueDtFim = null
      this.filters.filPeriodoDesembarqueDtInicio = null
      this.filters.filPeriodoDesembarqueDtFim = null
      //  weekly monthly yearly
      this.filters.filPeriodo = 'monthly'
      //  Operação
      this.filters.filTipoOperacaoModalExportacao = 1
      this.filters.filTipoOperacaoModalImportacao = 2
      this.filters.filTipoOperacaoModalNacional = 3
      //  Modal
      this.filters.filTipoOperacaoModalAereo = 1
      this.filters.filTipoOperacaoModalMaritimo = 2
      this.filters.filTipoOperacaoModalTerrestre = 3
      //  Situação
      this.filters.situacaoAbertos = 1
      this.filters.situacaoEmbarcados = 2
      // Period Date
      this.filters.filPeriodoDtInicio = (dayjs().add(-1, 'year').date(1).hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss')
      this.filters.filPeriodoDtFim = dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss')
      // Origin  and destinations
      this.filters.filOrigem = null
      this.filters.filDestino = null
      this.filters.filTipoEquipamento = []

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      /* Todo: Reload Charts */
      this.getKpiData()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    // End Filter
    // View filters
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (number) {
        return util.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showDecimal (number, decimals, decPoint, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      decimals = decimals || 2
      decPoint = decPoint || ','
      thousandsSep = thousandsSep || '.'

      if (number) {
        return util.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    // End View filters
    // Chart
    addPieData (chart, current, total, decimals) {
      decimals = (typeof decimals !== 'undefined') ? decimals : 0

      var totalValue = current + total

      var colors = [
        '#FFD863',
        '#3AA6D0'
      ]

      current = typeof current !== 'undefined' ? current : 0
      totalValue = typeof totalValue !== 'undefined' && totalValue > 0 ? totalValue : 100

      chart.data.datasets = []

      chart.update()

      let percent = current * 100 / totalValue
      let totalPercent = total * 100 / totalValue

      let labels = [
        this.numberFormat(current, decimals, ',', '.'),
        this.numberFormat(total, decimals, ',', '.')

      ]

      if (percent === 0 && totalPercent === 0) {
        colors = []
        labels = []
      }

      let dataSet = {
        data: [(percent.toFixed(2)), (100 - percent).toFixed(2)],
        backgroundColor: colors,
        label: labels,
        borderWidth: 0
      }

      chart.config.options.elements.center.text = percent.toFixed(0) + '%'

      chart.data.datasets.push(dataSet)

      chart.update()
    },
    addChartBarData (chart, data) {
      this.legend = []
      chart.data.labels = []
      chart.data.datasets = []

      chart.update()

      chart.data.labels = data.labels

      for (var propertyName in data) {
        let item = data[propertyName]

        let chartData = []
        let chartLabels = []

        let hideLabels = item.hideLabels || false

        for (let value in item.values) {
          let itemValue = item.values[value]

          chartData.push(itemValue.value)
          chartLabels.push(itemValue.label)
        }

        let dataSet = {
          attribute: item.year,
          hideLabels: hideLabels,
          backgroundColor: item.color,
          stack: item.year,
          data: chartData
        }

        if (item.type === 'area') {
          dataSet.type = 'line'
          dataSet.fill = false
          dataSet.borderWidth = 2
          dataSet.lineTension = 0.1
          dataSet.borderColor = Chart.helpers.color(`${item.color}`).rgbString()
          dataSet.backgroundColor = Chart.helpers.color(`${item.color}`).alpha(0.5).rgbString()
          dataSet.fill = true
          chart.options.spanGaps = true
        }

        if (item.type === 'line') {
          dataSet.type = 'line'
          dataSet.fill = false
          dataSet.borderWidth = 2
          dataSet.lineTension = 0.1
          dataSet.borderColor = Chart.helpers.color(`${item.color}`).rgbString()
          dataSet.backgroundColor = Chart.helpers.color(`${item.color}`).alpha(0.5).rgbString()
          chart.options.spanGaps = true
        }

        chart.data.datasets.push(dataSet)
        chart.data.labels = chartLabels
        chart.update()
      }
    },
    newBarChart (selector, labelFormatter, hideGridLine, hideLabelsTitle) {
      labelFormatter = labelFormatter || false
      hideGridLine = hideGridLine || false
      hideLabelsTitle = hideLabelsTitle || false

      let MONTHS_SH = [
        (this.$i18n.t('date.month.Jan')).substring(0, 3),
        (this.$i18n.t('date.month.Feb')).substring(0, 3),
        (this.$i18n.t('date.month.Mar')).substring(0, 3),
        (this.$i18n.t('date.month.Apr')).substring(0, 3),
        (this.$i18n.t('date.month.May')).substring(0, 3),
        (this.$i18n.t('date.month.Jun')).substring(0, 3),
        (this.$i18n.t('date.month.Jul')).substring(0, 3),
        (this.$i18n.t('date.month.Aug')).substring(0, 3),
        (this.$i18n.t('date.month.Sep')).substring(0, 3),
        (this.$i18n.t('date.month.Oct')).substring(0, 3),
        (this.$i18n.t('date.month.Nov')).substring(0, 3),
        (this.$i18n.t('date.month.Dec')).substring(0, 3)
      ]

      var config = {
        type: 'bar',
        data: {
          labels: MONTHS_SH

        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                if (hideLabelsTitle) {
                  return ''
                }

                let label = data.datasets[tooltipItem[0].datasetIndex].label

                if (label) {
                  return data['labels'][tooltipItem[0]['index']] + ' (' + label + ')'
                } else {
                  return data['labels'][tooltipItem[0]['index']]
                }
              },
              label: function (tooltipItem, data) {
                let currentColumn = ''
                let currentStack = ''
                let dataset = data.datasets[tooltipItem.datasetIndex]

                if (!dataset.hideLabels) {
                  currentColumn = data.datasets[tooltipItem.datasetIndex].attribute + ': '
                  currentStack = data.datasets[tooltipItem.datasetIndex].stack + ': '
                }
                let valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                // Loop through all datasets to get the actual total of the index
                var total = 0
                for (var i = 0; i < data.datasets.length; i++) {
                  let dataSet = data.datasets[i]

                  if (dataSet.stack === currentStack) {
                    total += data.datasets[i].data[tooltipItem.index]
                  }
                }

                // If it is not the last dataset, you display it as you usually do
                if (data.datasets[tooltipItem.datasetIndex].total) {
                  if (total > 0) {
                    if (labelFormatter) {
                      return [currentColumn + labelFormatter(total)]
                    }

                    return [currentColumn + (total)]
                  } else {
                    return false
                  }
                } else { // .. else, you display the dataset and the total, using an array
                  if (valor > 0) {
                    if ({}.toString.call(labelFormatter) === '[object Function]') {
                      return currentColumn + labelFormatter(valor)
                    }

                    return currentColumn + (valor)
                  } else {
                    return false
                  }
                }
              },
              afterLabel: function (tooltipItem, data) {
                /* var dataset = data['datasets'][0];
                  var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                  return '(' + percent + '%)'; */
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 14,
            titleSpacing: 10,
            titleMarginBottom: 10,
            bodySpacing: 4,
            titleFontColor: '#3f3f3f',
            bodyFontColor: '#333',
            bodyFontSize: 13,
            cornerRadius: 3,
            yPadding: 10,
            xPadding: 10,
            displayColors: true
          },
          elements: {
            rectangle: {
              borderWidth: 2
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          axes: {
            display: false
          },
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.95,
              barPercentage: 1.0,
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              },
              scaleLabel: {
                display: true,
                labelString: this.$i18n.t('period.title')
              }
            }],
            yAxes: [{
              stacked: false,
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: this.$i18n.t('quantity')
              }
            }]
          }
        }
      }

      if (hideGridLine) {
        config.options.scales.yAxes = [{
          stacked: false,
          categoryPercentage: 1.0,
          barPercentage: 0.5,
          ticks: {
            display: false
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0)'
          }
        }]
      }

      var ctxProcesses = document.getElementById(selector).getContext('2d')
      return new Chart(ctxProcesses, config)
    },
    newPie (selector) {
      var config = {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [0, 100],
            backgroundColor: []
          }],
          labels: []
        },
        options: {
          elements: {
            center: {
              text: '0%',
              color: '#00bcd4', // Default is #000000
              fontStyle: 'Arial', // Default is Arial
              sidePadding: 60 // Defualt is 20 (as a percentage),
            }
          },
          tooltipFontSize: 10,
          tooltips: {
            callbacks: {
              title: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem[0].datasetIndex].label[tooltipItem[0].index]

                if (!label) {
                  return null
                }

                return data['labels'][tooltipItem[0]['index']]
              },
              label: function (tooltipItem, data) {
                var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                if (valor <= 0) {
                  return null
                }

                var label = data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index]

                if (label) {
                  return label
                }

                return false
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 14,
            titleSpacing: 10,
            titleMarginBottom: 10,
            bodySpacing: 4,
            titleFontColor: '#3f3f3f',
            bodyFontColor: '#333',
            bodyFontSize: 13,
            cornerRadius: 3,
            yPadding: 10,
            xPadding: 10,
            displayColors: true
          },
          legend: {
            display: false
          },
          cutoutPercentage: 0,
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            animateScale: true,
            animateRotate: true
          }
        }
      }

      let processesDonnutctx = document.getElementById(selector).getContext('2d')

      return new Chart(processesDonnutctx, config)
    },
    // End Chart
    getRandomColor () {
      // var letters = '0123456789ABCDEF'.split('')
      // var color = '#'
      // for (var i = 0; i < 6; i++ ) {
      //   color += letters[Math.floor(Math.random() * 16)]
      // }
      // return color
      var r = Math.floor(Math.random() * 255)
      var g = Math.floor(Math.random() * 255)
      var b = Math.floor(Math.random() * 255)
      return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    },
    getKpiData () {
      let me = this
      this.isLoading = true

      if (this.$store.getters.userInfo) {
        KpiService.getShippingWweight(this.$store.getters.userInfo.customer_id, this.filter.period, this.filter).then(res => {
          me.kpiDados = res.data

          me.addChartBarData(this.fretePeriodoLinhaChart, res.data.data)
          me.addChartBarData(this.fretePeriodoBarraChart, res.data.exchangeRate)

          me.isLoading = false
        })
      }
    },
    buildCharts () {
      this.fretePeriodoLinhaChart = this.newBarChart('FretePeriodoLinha', this.showDecimal)
      this.fretePeriodoBarraChart = this.newBarChart('FretePeriodoBarra', this.showDecimal, true, true)
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }

  },
  watch: {
    user: function () {
      this.getKpiData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .chart-container {
    position: relative;
    margin: auto;
    width: 100%;
    padding: 0 60px;
  }

  .chart-legend {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .chart-container-frete-periodo-barra {
    height: 100px;
  }

  .chart-container-frete-periodo-linha {
    height: 250px;
    height: 40vh;
    margin-bottom: 15px;
  }

</style>
